﻿@import "colors.scss";

.kt-visitMotivePicker {

    .kt-tabs {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(94px, 94px));
        max-height: 100px;
        overflow-y: auto;

        &.kt-tabs-no-margin {
            margin: 0 !important;
        }

        .kt-tab {
            cursor: pointer;
            color: $font-color;
            background-color: white;
            padding-left: 5px;
            padding-top: 3px;
            border: 1px solid $control-color-light;
            text-align: center;
            text-decoration: none;
            z-index: 9;

            &:hover {
                background-color: $secondary-color-light;
                color: white;
            }

            &.kt-selected {
                background-color: $secondary-color;
                border-bottom-color: $primary-color;
                color: white;
            }
        }
    }

    .kt-visitMotive-row {
        margin-top: 10px;

        display: grid;
        grid-template-columns: auto 150px;

        select {
            height: 30px;
        }
    }

    @media screen and (max-width: 980px) {

        .kt-tabs {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(50px, 92px));
        }

        .kt-visitMotive-row {
            margin-top: 10px;

            display: grid;
            grid-template-columns: auto;
            row-gap: 3px;

        }
    }
}
