﻿@import "../colors.scss";
@import "../dimensions.scss";

.kt-calendar-page {
    display: grid;
    grid-template-columns: 200px auto;
    //grid-template-areas:  "left main";


    .kt-calendar-page-header {
        grid-area: header;
        background-color: $row-hover-color-light;
        display: grid;
        grid-template-columns: auto auto auto;
        align-content: center;

        .kt-time-span-button-group {
            text-align:right;
            padding-right:20px;
            .btn {
                margin: 1px;

                &:hover, &.btn-active {
                    background-color: grey;
                    color: white;
                }
            }
        }
    }

    .kt-calendar-page-left {
        grid-area: left;
        border-right: 1px solid $grid-line-color;
    }

    .kt-calendar-container {
        //grid-area: main;
    }
}

.kt-calendar-page-left {
    .kt-top-spacer {
        height: 51px;
        background-color: $calendar-top-toolbar-bg-color;
    }
    .kt-datePicker {

    }

    .MuiAccordionDetails-root {
        padding: 8px 4px 16px;
    }

    .kt-clonr-link {
        display: flex;
        background-color: $primary-color;
        border: none;
        border-radius: 5px;
        color: white;
        margin: 20px auto;        
        height: 40px;
        width: 150px;
        justify-content: center;
    }

    .kt-settings-link {
        display: flex;        
        border-radius: 5px;        
        margin: 20px auto;        
        height: 40px;
        width: 150px;
        justify-content: center;
    }
}
