.kt-clonr-avatar-list-ctrl {

    .kt-video-avatar-list-wrapper {

        max-height: auto;
        overflow-y: auto;

        max-height: calc(100vh - 80px);

        .kt-video-avatar-list {

            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            div {
                border-radius: 10px;
                border: 1px solid lightgray;
                box-sizing: border-box;
                background-size: cover;
                background-position: center;
                backdrop-filter: blur(3px);
                width: 126px;
                height: 126px;
                overflow: hidden;

                &.kt-selected {
                    border: 2px solid $primary-color;
                    color: $primary-color;
                }

                &:hover {
                    cursor: pointer;
                    border: 2px solid $primary-color;
                    color: $primary-color;
                }

                &.kt-avatar-in-progress {
                    opacity: 0.5;

                    .kt-avatar-spinner {
                        position: relative;
                        top: calc(-50% - 20px);
                        left: calc(50% - 20px);
                        height: 40px;
                        width: 40px;
                        border: none;
                        backdrop-filter: none;
                    }

                    &:hover {
                        cursor: default;
                        border: 1px solid lightgray;
                    }
                }


                img,
                .kt-voice-icon {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    color: $secondary-color;
                }

                img {
                    object-fit: contain;
                    object-position: center;
                    backdrop-filter: blur(5px);
                }
            }

        }
    }
}