.kt-avatar {
    
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid lightgrey;

    img {
        display: inline;
        margin: 0 auto;
        //margin-left: -35%; //centers the image
        height: 100%;
        width: auto;
    }
  
}