$top-nav-bar-appointment-color: $primary-color;
$top-nav-bar-recaller-color: rgb(21, 255, 0);


.topNavBar {

    z-index: 10;

    .kt-side-menu-close-button {
        
        text-align: center;
        background-color: $primary-color;
        color: white;
        padding: 10px;
        
        border-radius: 5px;

        position: fixed;
        top: 10px;
        left: 10px;
        right: 10px;
        height: 40px;
    }

    nav {
        background-color: $secondary-color;
        //background-image: linear-gradient(to left, $secondary-color, $secondary-color-light) !important;
        padding: 0;
        margin: 0;

        border-bottom: 2px solid $top-nav-bar-appointment-color;

        &.top-nav-bar-recaller {
            border-bottom: 2px solid $top-nav-bar-recaller-color;
        }

        display: grid;
        grid-template-columns: minmax(150px, 1fr) auto 1fr;
        grid-template-rows: 53px;
        align-items: center;

        a {
            color: white;
            text-decoration-line: none;
            margin: 11px 10px 0 10px;
        }

        button {
            color: white;
            text-decoration-line: none;
            margin: 0 10px;
            background: none;
            border: none;
            cursor: pointer;
        }

        i {
            color: white;
            margin-right: 3px;
        }

        li {
            font-size: 0.8rem;            
        }

        li.search {                
            .MuiFormControl-root {margin:0;}
        }

        .kt-main-nav, .navbar-nav-mobile{
            li:hover {
                i {
                    color:$primary-color;
                    opacity: 0.7;
                }
            }

            .isSelected {
                i {
                    color:$primary-color;
                }
            }
        }

        .navbar-brand {
            display: flex;
            align-items: center;
            margin: 0;
            padding-left: 20px;
            width: 140px;

            .navbar-brand-name {
                //font-size: 1.2em;
                margin-left: 10px;
                color: white;
            }
        }

        .navbar-nav {
            display: flex;
            align-items: center;

            list-style: none;
            color: white;

            li {
                display: inline-block;

                &.search {

                    //display: flex;
                    //align-items: center;


                    input {
                        background-color: $secondary-color-light;
                        color: white !important;
                        border: none;
                        max-width: 300px;
                        height: 10px;
                        border-radius: .25rem;
                        font-size: 1.05rem;


                        &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: white;
                            opacity: 1; /* Firefox */
                        }

                        &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                            color: white;
                        }

                        &::-ms-input-placeholder { /* Microsoft Edge */
                            color: white;
                        }

                        &:focus {
                            background-color: white;
                            color: grey !important;

                            &::placeholder {
                                color: grey !important;
                            }
                        }
                    }

                }

            }

        }

        .navbar-settings {

            display: flex;
            align-items: center;
            justify-content: flex-end;

            list-style: none;

            .kt-username {
                color: white;
                margin-right: 15px;
            }
            .dropdown-toggle:hover {
                cursor: pointer;
            }

            .dropdown {
                min-width: 30px;
            }

            .dropdown-menu {
                position: absolute;
                background-color: white;
                z-index: 999;
                border-radius: 3px;
                border: 1px solid $control-color;
                padding: 10px 0 10px 0;

                top: 45px;
                margin-left: -115px;

                i {
                    color: $dropdown-text-color;
                    margin-right: 10px;
                }

                .dropdown-item {
                    color: $dropdown-text-color;
                    text-decoration-line: none;
                    margin: 11px 10px 0 20px;
                    cursor: pointer;

                    &:hover i{
                        color: $primary-color;
                    }
                }


                .dropdown-divider {
                    margin: 10px;
                    border-top: 1px solid $control-color;
                }
            }
        }



        @media screen and (min-width: 980px) {
            .navbar-nav-mobile, .kt-mobile {
                display: none;
            }
        }

        @media screen and (max-width: 1200px) {
           
            li {
                font-size: 0.65rem;
            }
        }

        @media screen and (max-width: 980px) {
                   
            .navbar-nav-desktop, .nav-item-desktop, .kt-desktop {
                display: none;
            }

            ul {
                padding-inline-start:0;

                li {
                    font-size: 1rem;;
                }
            }

            li.search {            
                input.form-control {
                    max-width: 190px;
                }
            }

            ul.navbar-settings li {
                margin-right: 10px,

            }

        }


    }

}