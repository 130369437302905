.kt-phoneCallTranscriptChatView {
    width: 100%;
    margin: auto;
    padding: 20px;
    background: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 470px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .chat-message {
        display: flex;
        align-items: flex-end;
    }

    .chat-message-user {
        justify-content: flex-end;
    }

    .chat-bubble {
        padding: 0;
        border: 1px solid transparent;
        border-radius: 18px;
        max-width: 70%;
        word-wrap: break-word;
        font-size: 14px;
        margin: 5px;

        &.kt-active {
            border-color: $primary-color;
        }
    }

    .chat-bubble-user {
        background-color: $primary-color-ultralight;     
        border-bottom-right-radius: 2px;
    }

    .chat-bubble-bot {
        background-color: #e0e0e0;
        color: black;
        border-bottom-left-radius: 2px;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin: 30px;
    }

    .chat-time {
        color: grey;
        &:hover {            
            cursor: pointer;
            color: $primary-color;
        }
    }

}