.kt-video-room-container {

    display: flex;
    flex-direction: column;

    position: absolute;
    height:100%;
   
    margin: 0;

    background-color: black;

    position: relative;
    overflow: hidden;
    
    text-align: center;
    //padding: 30px;

    align-items: center;
    
    .kt-video-container {

        //margin: auto;
        //background-color: rgb(12, 12, 12);

        &.kt-remote-video {
            padding:0;

            video {
                position: absolute;
                left: 50%;
                top: 50%;

                transform: translate(-50%, -50%);
                background-color: rgb(29, 29, 29);
                   
                margin: 0;

                min-width: 100%;
                min-height: 100%;
            }

        }

        &.kt-local-video {
            position: absolute;
            right: 0;
            top: 0;
            video {
                height: 100px;
                width: 100px;    

                margin-top: 10px;
                border-radius: 2px;
            }
        }

        
    }

    

    .kt-button-circle {
        width: 48px;
        height: 48px;
        background-color: $secondary-color;
        color: white;
        margin: 5px 10px;

        &.kt-background-red {
            background-color: red;
        }

        &.kt-background-green {
            background-color: rgb(0, 155, 0);
        }

        &:hover, &.Mui-focusVisible {
            background-color: $primary-color;
        }
    }

    .kt-waiting-for-remote {
        width: 100%;;
        position: absolute;
        top: 130px;
        color: white;                
    }


    .kt-button-send-sms {                
        margin: auto;
    }



    .kt-remote-video-label {                
        position: absolute;
        bottom: 100px;

        width: fit-content;
        margin: auto;                
        
        color: white;
        background-color: rgba(33, 33, 33, 0.221);
        padding: 2px 5px;
        border-radius: 3px;
        //opacity: 0.8;
        text-overflow: ellipsis;                
    }

        
    .kt-call-buttons {
        position: absolute;
        bottom: 20px;
    }

    .kt-bottom-section {
        border-top: 1px solid $secondary-color;
        margin-top: 10px;
        margin-bottom: 10px;
        height: 100px;
        width: 100%;
    }


    @media screen and (max-width: 980px) {
        .kt-video-container {
            
            video {
                &.kt-remote-video {
                    height: 60%;
                    width: 85%;                
                }
        
                &.kt-local-video {
                    height: 80px;                    
                }
            }
        }

        .kt-bottom-section {
            height: 150px;
        }
        
    }
  
}