.kt-clonr-mobile-menu {
    display: none;
}

@media screen and (max-width: 1400px) {

    .kt-clonr-mobile-menu {
        position: fixed;
        z-index: 999;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: white;
        height: 60px;
        width: 100%;
        border-top: 2px solid $primary-color;

        display: flex;
        align-items: center;
        justify-content: space-around;

        a {
            text-decoration: none;
        }

        .kt-menu-item {
            width: 50px;
            height: 50px;
            border: 1px solid $secondary-color;
            border-radius: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;


            i {
                font-size: 18px;
            }

            span {
                font-size: 10px;
            }

            &.kt-selected {
                color: $primary-color;
                border-color: $primary-color;
            }
        }
    }
   
}