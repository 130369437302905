﻿@import "../colors.scss";
@import "../dimensions.scss";

.kt-recaller-page {

    .kt-recall-logo {
        height: 120px;
    }

    table {
        td {
            padding: 3px !important;
        }
    }

    .kt-no-data {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .kt-recall-logo {
            max-width: 500px;
        }
    }


    .kt-two-columns {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .kt-cards-container {
        height: calc(100% - 270px) !important;
        overflow-y: auto;
        position: absolute;
    }
}


.kt-campaign-page {

    // .kt-data-grid-container {
    //     margin-top: 15px;
    //     width: 100%;
    //     height: calc(100% - 450px) !important;
    // }



    .kt-form-section {
        // position: absolute;
        // top: 191px;
        // bottom: 10px;
        // left: 15px;
        // right: 15px;
    }
}