.kt-videoCall-page {
    //background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    //color: white;
    .kt-page {

        position: absolute;
        top: 53px;
        bottom: 0;
        padding: 0;
        
        h2{
            color: white !important;
        }
    }
}