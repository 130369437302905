
@-webkit-keyframes color_change {
	from { background-color: $primary-color-ultralight; }
	to { background-color: white; }
}
@-moz-keyframes color_change {
	from { background-color: $primary-color-ultralight; }
	to { background-color: white; }
}
@-ms-keyframes color_change {
	from { background-color: $primary-color-ultralight; }
	to { background-color: white; }
}
@-o-keyframes color_change {
	from { background-color: $primary-color-ultralight; }
	to { background-color: white; }
}
@keyframes color_change {
	from { background-color: $primary-color-ultralight; }
	to { background-color: white; }
}



.kt-dropzone {

	box-sizing: content-box;
    text-align: center;
    border: 1px dashed;
    border-radius: 5px;
    border-color: $primary-color;

	max-width: 550px;
	margin: auto;

    -webkit-animation: color_change 2s infinite alternate;
	-moz-animation: color_change 2s infinite alternate;
	-ms-animation: color_change 2s infinite alternate;
	-o-animation: color_change 2s infinite alternate;
	animation: color_change 2s infinite alternate;

    i {
        font-size: 50px;
    }

    &:hover {
        background-color: $primary-color-ultralight;
		border: 1px solid $primary-color;
		cursor: pointer;
		color: $primary-color;
    }
}