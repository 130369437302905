﻿// $primary-color: #d83ac3;
// $primary-color-light: #c790c0;

// $secondary-color: #24374e;
// $secondary-color-light: #405064;

$primary-color: #d862c8;
$primary-color-light: #c790c0;
$primary-color-ultralight: #f8e1f5;

$secondary-color: #24374e;
$secondary-color-light: #405064;
$secondary-color-ultralight: #cedbeb;

$almost-white: rgb(240, 240, 240);

$font-color: #4b6d83;
$link-color: $secondary-color;

$btn-color: $secondary-color-light;

$control-color: rgb(200,200,200);
$control-color-light: rgb(238, 238, 238);

//$appointment-background-color: rgb(245, 245, 245);
$appointment-font-color: white;
$appointment-background-color: $secondary-color-light;
$appointment-background-color-shaded: #2C3E50;
$appointment-background-color-absence: $primary-color-light;
$appointment-border-color: rgb(214, 214, 214);
$appointment-border-color-new-patient: rgb(3, 209, 65);
$appointment-border-color-private-insurance: rgb(187, 3, 3);

$background-event-background-color: #f1f1f1;
$background-event-background-color-shaded:rgb(247, 247, 247);

$calendar-top-toolbar-bg-color: #f7f7f7;

$holiday-color: #4b70be;

$grey-page-background-color: rgb(250, 250, 250);

$row-color: $control-color-light;
$row-hover-color: $control-color;
$row-hover-color-light: $control-color-light;
$row-hover-text-color: white;
$grid-line-color: rgb(230, 230, 230);

$event-background-color: rgb(173, 229, 249);
$event-background-color-left: rgb(67, 204, 252);
$event-text-color-dark: black;
$event-text-color: rgb(73, 73, 73);

$date-picker-light-color: #c1c1c1;
$date-picker-border-color: rgb(102, 102, 102);

$dialog-body-color: rgb(245, 245, 245);



$input-border-color: $control-color;
$input-text-color: #2a2a2a;

$panel-icon-color: #c0d3e0;

$dropdown-text-color: rgb(121, 121, 121);

$confirm-color: green;


$speech-bubble-color: lightgrey;

$form-font-color: #363636;

$bot-value-color: $secondary-color;

$docs-complete-color: rgb(1, 222, 1);
$docs-incomplete-color: red;
$docs-sent-color: orange;

$toaster-color: $font-color;
$toaster-background-color: white;
