.kt-phone-calls-page {

    .MuiDataGrid-cell {
        padding: 10px !important;
    }

    .kt-phone-call-category-chip {
        color: white;
        padding: 5px;
        font-size: 10px;
        display: inline-flex;
        margin: 3px;

        i {
            color: white;
            font-size: 12px;
        }
    }
}