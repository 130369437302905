
.kt-video-selector-ctrl-popup {

    &.kt-video {

        display: grid;
        grid-template-columns: 70px auto;
        align-items: center;
        .kt-video-thumbnail {
            width: 64px;
            height: 64px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            border: 1px solid lightgrey;
            border-radius: 2px;
        }

        .kt-video-name {
            text-wrap: auto;
        }

        .kt-language {
            border: 1px solid lightgrey;
            border-radius: 3px;
            width: 15px;
            height: 15px;
            font-size: 12px;
            padding: 2px;
            margin: 2px;
        }
    }
}