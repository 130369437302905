.kt-language-button {
    border: 1px solid grey;
    margin: 2px;
    padding: 0 2px; 
    border-radius: 3px;
    cursor: pointer;
    display: inline-block;
    line-height: 15px;

    &.kt-large {
        border-radius: 100%;
        font-size: 17px;        
        padding: 3px;
        padding-top: 10px;
        width: 40px;
        height: 40px;        
    }

    &:hover {
        border-color: $primary-color;
        color: $primary-color;
    }

    &.kt-selected {
        color: white;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}

.kt-grid-main {
    grid-area: main;
    display: grid;
    grid-template-rows: auto 200px;
    gap: 10px;
}

.kt-grid-footer {
    grid-area: footer;
}

.kt-clonr {
    padding: 10px;
    display: grid;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgb(232, 232, 232);

    grid-template-columns: 170px 1fr;
    grid-template-rows: 1fr 50px;

    grid-template-areas:
        'menu main'
        'footer footer';

    gap: 10px;

    a {
        text-decoration: none;
    }

   
    .kt-loading {
        text-align: center;
        margin-top: 20px;
        font-size: 20px;
    }

    .kt-big-add-button {
        width: 250px;
        margin: auto;
        text-align: center;
        color: $secondary-color;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid lightgrey;
        padding: 10px;
        box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
        height: 120px;

        i {
            color: $primary-color;
            font-size: 64px;
            margin-bottom: 10px;
        }

        img {
            width: 64px;
        }

        &:hover {
            border-color: $primary-color;

            strong {
                color: $primary-color !important;
            }
        }
    }

    video {
        border-radius: 10px;
    }

    .kt-paper {
        height: 100%;
        padding: 20px;
        color: $font-color;
        position: relative;

        overflow-y: auto;

        .kt-paper-title {
            font-size: 25px;
            font-weight: bold;
        }

        .kt-circle-plus-btn {
            background-color: $secondary-color;
            color: white;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            font-size: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            cursor: pointer;

            &:hover {
                background-color: $primary-color;
            }
        }

    }

    .kt-merge-grid {        
        position: relative;
        height: 100%;
        display: grid;
        grid-template-columns: 320px minmax(0, 1fr) 320px;
        grid-template-rows: 1fr 350px 100px;
        grid-template-areas:
            "avatars avatar voices"
            "avatars script voices"
            "avatars actions voices";

        gap: 10px;

        .kt-video-avatar {
            grid-area: avatar;
            text-align: center;

            video {
                max-height: 350px;
                width: 70%;
                margin: auto;
                border-radius: 10px;
                border: 1px solid lightgrey;
            }
        }

        #clonRAvatarPreviewVideo {
            max-height: 280px;
        }

        .kt-actions {
            grid-area: actions;
        }

        .kt-video-script-wrapper {
            grid-area: script;
            background-color: white;

            .kt-video-script,
            .kt-audio-script {
                height: 300px;
            }

            .kt-audio-script {
                padding-top: 30px;
                text-align: center;
            }

            .kt-video-script {
                .kt-script-buttons {
                    display: grid;
                    grid-template-columns: 120px 120px auto 120px 120px 120px;
                    gap: 10px;
                }
            }
        }

        .kt-clonr-voice-list-ctrl {
            grid-area: voices;
        }

        .kt-clonr-avatar-list-ctrl {
            grid-area: avatars;
        }

    }

    .kt-generate-clonr-video-btn-wrapper {
        display: flex;
        justify-content: center;
        gap: 10px;

        .kt-generate-clonr-video-btn {
            width: 100%;
            max-width: 250px;
            height: 100%;

            span {

                font-size: 18px;

                display: flex;
                flex-direction: row;
                align-content: center;
                gap: 10px;

                i {
                    font-size: 30px;
                }
            }
        }
    }


    .kt-merge {
        padding: 0;
        text-align: center;
        display: flex;
        align-items: center;


        .kt-merge-icon {
            position: absolute;
            height: 50px;
            font-size: 40px;
            right: -19px;
            top: calc(50% - 25px);
            display: flex;
            align-items: center;
        }

        .kt-merge-avatar {
            display: flex;
            flex-direction: column;
            position: relative;
            min-height: 150px;
            padding: 0 !important;
            align-items: center;
            justify-content: center;

            i {
                font-size: 30px;
            }

            img {
                width: 150px;
                border-radius: 10px;
            }
        }

        .kt-merge-voice {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;

            i {
                font-size: 30px;
            }
        }

        .kt-merge-script {
            position: relative;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;
            padding: 20px;

            i {
                font-size: 30px;
            }

            .kt-script-flags {
                display: flex;
                flex-wrap: wrap;

                .kt-script-flag {
                    display: flex;
                    align-items: center;
                    width: 50px;
                    cursor: pointer;
                    margin-right: 5px;
                    padding: 2px;
                    border: 1px solid transparent;
                    border-radius: 2px;

                    span {
                        margin-left: 5px;
                    }

                    &.kt-selected {
                        border: 2px solid $primary-color;
                    }
                }
            }
        }

    }

    .kt-video-card {
        box-shadow: none;
        padding: 10px;

        transition: margin-top 0.1s linear;

        .MuiCardMedia-root {
            border-radius: 5px;
            border: 1px solid lightgrey;
        }

        .fa-trash:hover {
            color: $primary-color;
        }

        div.kt-video-play-overlay-button-wrapper {
            display: none;
            position: "relative" !important;
            left: "100px" !important;
            top: "-90px" !important;
            height: 0;

            .kt-video-play-overlay-button {
                border-radius: 50%;
                background-color: white;
                width: 40px;
                height: 40px;
                color: $primary-color;
                display: flex;
                align-items: center;
                justify-content: center;

                i {
                    font-size: 20px;
                }
            }
        }

        .kt-video-status {
            position: relative;
            height: 0;
            top: -25px;
            left: 5px;

            &>div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: fit-content;
                height: 18px;
                padding: 2px 5px;
                border-radius: 3px;
                background-color: $primary-color-ultralight;
                color: $primary-color;
                font-size: 12px;
            }

        }

        

        &:hover {
            box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            cursor: pointer;
            margin-top: -5px;

            .kt-video-play-overlay-button-wrapper {
                display: block;
            }
        }
    }

    .kt-dark {
        color: white;
        background-color: $secondary-color;
    }

    .kt-scripts {
        .kt-script-item {
            display: grid;
            grid-template-columns: auto 22px 22px;
            gap: 5px;
            align-items: center;

            border: 1px solid $secondary-color;
            border-radius: 3px;
            margin-bottom: 5px;
            padding: 0;

            cursor: pointer;

            .kt-script-item-content {
                display: grid;
                grid-template-columns: 128px auto;
                align-items: center;
                gap: 10px;

                img {
                    width: 128px;
                    //height: 128px;
                    //object-fit: contain;
                    border-radius: 10px;
                    margin: 5px;
                }


                .kt-video-status {
                    font-size: 12px;

                    i {
                        font-size: 16px;
                    }
                }
            }

            div:first-child {
                padding: 3px;
            }

            &:hover {
                border-color: $primary-color;
            }

            &.kt-selected {
                background-color: $primary-color-ultralight;
                //color: white;
            }

            .kt-icon-btn {
                text-align: center;

                :hover {
                    color: $primary-color;
                }
            }
        }
    }
}


.kt-voice-icon {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    font-size: 30px;

    width: 128px;
    height: 128px;
    border-radius: 10px;
    border: 1px solid lightgray;
    color: $secondary-color;
    cursor: pointer;

    .kt-voice-name {
        position: absolute;
        font-size: 14px;
        top: 5px;
        text-align: center;
    }

    .kt-buttons {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 12px;
        position: absolute;
        bottom: 5px;

        div {
            border: 1px solid transparent;
            width: 20px;
            height: 20px;
            border-radius: 3px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
                border-color: $primary-color;
                color: $primary-color;
            }

            .fa-stop {
                color: $primary-color;
            }
        }
    }

    &:hover,
    &.kt-selected {
        border: 2px solid $primary-color;
        //color: $primary-color;
    }
}


.kt-big-add-button {
    display: block;
}




@media screen and (max-width: 1400px) {

    .kt-clonr-avatar-list-ctrl.kt-hide-on-mobile, .kt-clonr-voice-list-ctrl.kt-hide-on-mobile {
        display: none;
    }


    .kt-clonr {
        display: flex;
        flex-direction: column;

        .kt-scripts,
        .kt-merge {
            border: 1px solid red !important;
            height: 50vh;
        }

        video {
            max-height: 50vh;
        }

    }

    #clonRAvatarPreviewVideo,
    #videoRecorderPreview,
    #recordedVideoPreview {
        max-width: 60vw;
        max-height: 50vh !important;
    }

    #clonRAvatarPreviewVideo {
        margin-top: 10px;
    }

    #clonRVideo {
        max-width: 70vw;
        max-height: 50vh;
    }
}


@media screen and (max-width: 1400px) {

    .kt-clonr {

        position: fixed;
        top: 0;
        bottom: 60px;
        left: 0;
        right: 0;
        height: auto;
        overflow-y: auto;


        .kt-paper {
            padding: 10px;
        }

        .kt-justify-content-center-mobile {
            justify-content: center;
        }

        .kt-merge-grid {

            gap: 5px;

            // grid-template-columns: 100%;
            // grid-template-rows: 300px 350px 60px;
            // grid-template-areas:
            //     "avatar"
            //     "script"
            //     "actions";
            display: flex;
            flex-direction: column;

            .kt-video-avatar {
                overflow-y: visible;

                video {
                    //max-height: 350px !important;
                }

                .kt-voice-icon {
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-content: center;
                    align-self: center;
                    align-items: center;

                    .kt-buttons {
                        position: relative;
                        bottom: unset;
                        div {
                                    
                            border: none;

                            .fa-volume,
                            .fa-stop {
                                // position: relative;
                                // top: -8px;
                                font-size: 18px;
                            }

                           
                        }
                    }

                    .fa-waveform-path {
                        display: none;
                    }

                    .kt-voice-name {
                        display: none;
                    }
                }

                .kt-clone-voice-select-button-mobile {
                    display: flex;
                    justify-content: center;
                    gap: 5px;
                    margin-top: 5px;
                }
            }

            .kt-video-script-wrapper {

                .kt-video-script {

                    .kt-margin-top-minus-15-mobile {
                        margin-top: -15px;
                    }

                    .kt-script-buttons {
                        //display: flex;
                        grid-template-columns: auto auto auto;
                        grid-template-rows: 25px 25px;
                        gap: 5px;

                        button {
                            font-size: 11px;
                        }
                    }
                }
            }

            
            .kt-voices-list-wrapper {

                display: none;
                max-height: auto;
                overflow-y: auto;
                
                .kt-voices-list {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    gap: 5px;
                }
            }

            .kt-generate-clonr-video-btn {
                max-width: 180px;
                font-size: 13px;

                span,
                span i {
                    font-size: 16px;
                    display: inline;
                }
            }

        }

        .kt-grid-footer {
            display: none;
        }




    }
}