.dot-elastic {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary-color;
    color: $primary-color;
    animation: dot-elastic 1s infinite linear;
  }
  .dot-elastic::before, .dot-elastic::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  .dot-elastic::before {
    left: -15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary-color;
    color: $primary-color;
    animation: dot-elastic-before 1s infinite linear;
  }
  .dot-elastic::after {
    left: 15px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: $primary-color;
    color: $primary-color;
    animation: dot-elastic-after 1s infinite linear;
  }

  @keyframes dot-elastic-before {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1.5);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @keyframes dot-elastic {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 1.5);
    }
    75% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
  @keyframes dot-elastic-after {
    0% {
      transform: scale(1, 1);
    }
    25% {
      transform: scale(1, 1);
    }
    50% {
      transform: scale(1, 0.67);
    }
    75% {
      transform: scale(1, 1.5);
    }
    100% {
      transform: scale(1, 1);
    }
  }





  .kt-pulse {
    margin: 30px auto;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #fa2929;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(204,169,44, 0.4);
    animation: kt-pulse 2s infinite;
  }
  .kt-pulse:hover {
    animation: none;
  }
  
  @-webkit-keyframes kt-pulse {
    0% {
      -webkit-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
    }
    70% {
        -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }
  @keyframes kt-pulse {
    0% {
      -moz-box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
      box-shadow: 0 0 0 0 rgba(204, 44, 44, 0.4);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
        box-shadow: 0 0 0 10px rgba(204, 44, 44, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
        box-shadow: 0 0 0 0 rgba(204,169,44, 0);
    }
  }




  @keyframes kt-pulse-opacity {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% { opacity: 0.4; }
  }

  @-webkit-keyframes kt-pulse-opacity {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% {opacity: 0.4; }
  }



.kt-pulse-opacity {        
    -webkit-animation: kt-pulse-opacity 5s infinite ease-in-out;
    -o-animation: kt-pulse-opacity 5s infinite ease-in-out;
    -ms-animation: kt-pulse-opacity 5s infinite ease-in-out; 
    -moz-animation: kt-pulse-opacity 5s infinite ease-in-out; 
    animation: kt-pulse-opacity 5s infinite ease-in-out;
}

