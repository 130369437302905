@import "colors.scss";

.kt-searchInput {
    
    input {
        width: 100%;
    }

    .MuiFormHelperText-root{
        margin-top:-1px;
        margin-bottom: -20px;
    }

    .kt-searchInput-dropdown {
        border: 1px solid grey;
        background-color: white;
        z-index: 999;
        max-height: 200px;
        overflow-y: auto;
        position: absolute;
        width: 585px;
        margin-left:-1px;

        font-family: 'Roboto', sans-serif;
        
        ul {
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                font-size: 14px;
                padding: 5px 10px 5px 10px;
                border-bottom: 1px solid lightgrey;
                display: block !important;
                color: $font-color;
                
                &:last-child{
                    border-bottom: none;
                }

                &:hover{
                    cursor: pointer;
                    background-color: lightgrey;
                }  

                span {
                    strong {
                        color: $secondary-color;
                    }
                }

                &.kt-selected{
                    background-color: lightgrey;
                }
            }
        }
    }

    @media screen and (max-width: 980px) {
        .kt-searchInput-dropdown {            
            position: relative;
            width: auto;            
        }
    }
}