.traffic-light {
    height: 50px;
    background: rgb(196,196,196);
    background: linear-gradient(90deg, rgba(196,196,196,1) 0%, rgba(255,255,255,1) 100%);
    padding: 10px;
    border: solid 2px #ffffff;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    

    .light {
        width: 30px;
        height: 30px;
        background-color: #ffffff;
        border-radius: 50%;
        margin: 0 5px;
        opacity: 0.2;
    }
    
    .red {
        background-color: $docs-incomplete-color;
        border: 1px dotted $docs-incomplete-color;
        &.active {
            box-shadow: 
            0 0 5px #111 inset,
            0 0 10px $docs-incomplete-color;        
        }
    }
    
    .yellow {
        background-color: $docs-sent-color;
        border: 1px dotted $docs-sent-color;
        &.active {
            box-shadow: 
            0 0 5px #111 inset,
            0 0 10px $docs-sent-color;        
        }
    }
    
    .green {
        background-color: $docs-complete-color;
        border: 1px dotted $docs-complete-color;
        &.active {
            box-shadow: 
            0 0 5px #111 inset,
            0 0 10px $docs-complete-color;        
        }
    }

    .black {
        background-color: black;
    }

    .active {
        opacity: 1;
    }
}

