

.kt-videos-sidebar {
    //display: none;
    position: absolute;
    width: 300px;
    height: 100%;
    right: -300px;
    z-index: 999;

    //opacity: 0.9;
    background-color: black;
    color: white;

    transition-property: right;
    transition-duration: 1s;

    overflow-y: auto;

    .kt-head {

        display: flex;
        justify-content: space-between;
        padding: 0 20px;

        h1 {
            color: white;
            font-size: larger;

        }

        button {
            border: none;
            color: white;
            background-color: transparent !important;
            cursor: pointer;
        }
    }



    .kt-video-container {

        margin: 20px;
        border-bottom: 1px solid white;

        .kt-video {

            margin: 5px 0;
            iframe {
                width: 250px;
                border: none;
            }
        }

        .kt-title {
            color: $primary-color;
            font-weight: 600;
        }

        .kt-description {

        }

    }
}