@import "./../colors.scss";

@keyframes bot-color-line-key-frame {
    from {text-shadow: 3px 3px 10px $primary-color, 3px 3px 10px $primary-color-light; color: $bot-value-color;}
    to {text-shadow:none; color: $font-color;}
}

@keyframes bot-color-key-frame {
    from {background-color: $primary-color;}
    to {background-color: $bot-value-color;}
}


@keyframes pulsate {
    0% {-webkit-transform: scale(0.1, 0.1); opacity: 0.0;}
    15% {opacity: 1.0;}
    30% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
    100% {-webkit-transform: scale(1.2, 1.2); opacity: 0.0;}
}


.ring-container {
    position: relative;

    .circle {
        width: 7px;
        height: 7px;
        background-color: $primary-color;
        border-radius: 50%;
        position: absolute;
        left: -16px;
        top: -4px;
    }

    .ringring {
        border: 3px solid $primary-color;
        -webkit-border-radius: 30px;
        height: 21px;
        width: 21px;
        position: absolute;
        left: -23px;
        top: -11px;
        -webkit-animation: pulsate 5s ease-out;
        -webkit-animation-iteration-count: infinite;
        opacity: 0.0
    }
}





.kt-page-with-side-menu {
    display: grid;
    grid-template-columns: 200px auto;

    &.kt-with-info-column {
        grid-template-columns: 200px auto 200px;
    }

    .kt-side-menu {
        //background-color: $secondary-color-light;
        color: white;
        padding: 0;
        z-index: 10;

        font-size: 0.9em;

        overflow-y: auto;

        a {
            text-decoration: none;
        }

        .MuiFormControl-root {
            background-color: white;
        }

        .kt-days-button-wrapper {
            margin-top: 5px;
            display: grid;
            grid-template-columns: auto auto;
            justify-content: stretch;
            column-gap: 2px;
            row-gap: 2px;

            button {
                color: white;
                background-color: $secondary-color;
                cursor: pointer;
                border: none;
                border-radius: 4px;
                padding: 2px;

                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
            }
        }



        a.kt-side-menu-item, .kt-side-menu-item {
            display: flex;
            align-items: center;
            border-bottom: 1px solid white;
            padding: 10px;
            cursor: pointer;
            text-decoration: none;
            color: $secondary-color;
            border-top-right-radius: 25px;
            border-bottom-right-radius: 25px;

            &:hover, &.kt-selected {
                background-color: $secondary-color-light;
                color: white;

                border-left: 2px solid $primary-color;
            }

            i {
                margin-right: 10px;
                width: 14px;
                text-align: center;
                color: white;
                background-color: $secondary-color;
                border-radius: 50%;
                width: 30px;
                height: 30px;
                padding-top: 8px;
            }


        }

        &.kt-white-side-menu {
            background-color: white;
            color: $font-color;
        }

        .kt-side-menu-logout-button {
            display: none;            
            text-align: center;
            background-color: white;
            color: $secondary-color;
            padding: 10px;
            
            width: 100%;
            bottom: 0;
            border-radius: 5px;
            border: 1px solid $secondary-color;
        }

        
        .kt-side-menu-spacer {
            height: 50px;
        }

        div.kt-side-menu-item-light {
            border-top: 1px solid $secondary-color;
            color: $secondary-color;
            text-align: center;

            &:hover {
                background-color: $secondary-color;
            }
        }

        div.kt-hide-on-desktop {
            display: none;
        }
    }

    .kt-right-panel {

        .kt-header {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            background-color: $secondary-color;
            color: white;
            padding: 12px;
            height: 51px;

            font-family: 'Roboto', sans-serif;


            .kt-dot {
                font-size: 8px;
                margin-right: 10px;
            }

            .kt-label {
                font-size: 18px;
                color: white;
            }
        }

        .kt-body {
            padding: 10px;


            .kt-robo-img {
                width: 100px;
                position: absolute;
                bottom: 0;
                right: 50px;
            }

            #kt-appointment-preview-panel {

                .kt-preview-panel-content {

                    color: $appointment-font-color;
                    background-color: $appointment-background-color;

                    font-size: 14px;

                    border-radius: 4px;
                    border: 1px solid $control-color;
                    border-left: 4px solid transparent;

                    padding: 5px;

                    //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

                    .kt-visit-motive {
                        margin-bottom: 7px;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .kt-private-insurance {
                        color: white;
                    }

                    .kt-new-patient {
                        color: $appointment-border-color-new-patient;
                    }

                    .kt-needs-confirmation {
                        color: red;
                    }

                    .kt-border-bottom {
                        border-bottom: 1px solid $secondary-color;
                    }
                }

            }

            #kt-bot-panel {

                font-family: 'Aldrich', sans-serif;
                text-transform: uppercase;
                font-size: 12px;

                .kt-bot-group {
                    text-align: right;
                    margin-bottom: 10px;

                    .kt-bot-group-title {
                        color: $primary-color;
                        font-size: 14px;
                        font-weight: 800;
                    }
                    .kt-bot-row {
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        margin-bottom: 0;

                        .kt-bot-event {
                            margin-right: 5px;
                            padding: 3px;
                        }

                        .kt-bot-value {
                            background-color: $bot-value-color;
                            width: 25px;
                            height: 20px;

                            color: white;
                            padding: 3px;
                            text-align: center;
                        }

                        &.kt-bot-color-animation {
                            .kt-bot-event {
                                animation-name: bot-color-line-key-frame ;
                                animation-duration: 5s;
                                animation-timing-function: ease-out;
                            }
                            .kt-bot-value {
                                animation-name: bot-color-key-frame ;
                                animation-duration: 5s;
                                animation-timing-function: ease-out;
                            }
                        }
                    }
                }
            }
        }
    }


}





.kt-dashboard {
    .kt-side-menu-item {
        display: block !important;

        &:hover, &.kt-selected {
            background-color: white !important;
            border-left-width: 0 !important;
            cursor:default !important;
        }
    }

}


.kt-page-content {
    padding: 10px;

    h2 {
        font-size: 1.3em;
        margin: 0;
    }

    h3 {
        font-size: 1em;
    }

    .kt-button-bar {
        display: grid;
        margin: 10px 0 10px 0;
        div{
            width: fit-content;
        }
    }

   

}

.kt-tab-buttons {
    display: grid;
    grid-template-columns: repeat(auto-fill, 140px);
    grid-column-gap: 2px;
    margin-top: 10px;

    a {
        border-top: 1px solid $font-color;
        border-left: 1px solid $font-color;
        border-right: 1px solid $font-color;
        text-align: center;
        text-decoration: none;
        &:hover, &.kt-selected {
            background-color: $font-color;
            color: white;
            cursor: pointer;
        }
    }
}

.kt-tab-content {
    border-top: 1px solid $font-color;
}

.kt-calendarCtrl-wrapper {
    height: 100%;

    &.kt-livebot {
        background-color: #e7eff9c1;
    }
}


@-webkit-keyframes slide {
    100% { left: 0; }
}

@keyframes slide {
    100% { left: 0; }
}

@media screen and (max-width: 980px) {
    .kt-page-with-side-menu {
        display: block;

        .kt-side-menu {
            
            position: absolute;
            width: 100%;
            left:-200px;

            top: 55px;
            bottom: 0;

            padding: 10px;

            //font-size: 8px !important;

            -webkit-animation: slide 0.2s forwards;
            animation: slide 0.2s forwards;


            div.kt-hide-on-desktop {
                display: block;
            }

            ul {
                li.kt-resource-filter-li{
                    font-size: 1em;

                    .kt-resource-filter-button {
                        font-size: 1.4em;
                    }
                }
            }
            a.kt-side-menu-item, div.kt-side-menu-item {

                &:hover {
                    background-color: white;
                }
            }
        }
    }



    .kt-hide-on-mobile {
        display: none;
    }

}

@media screen and (max-width: 980px) {
    .kt-right-panel {
        display: none;
    }

    .kt-page-with-side-menu {

        &.kt-with-info-column {
            grid-template-columns: 200px auto;
        }
    }
}
