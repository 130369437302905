.kt-transfer-list {

    display: grid;
    grid-template-columns: auto 100px auto;
    gap: 10px;
    min-height: 400px;
    max-height: 600px;

    .kt-transfer-buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}