﻿@import "colors.scss";
@import "dimensions.scss";

.kt-calendar-container {
    display: grid;
    grid-auto-flow: column;
    background-color: white;

    .kt-calendar-day-column {
        display: grid;
        border-left: 1px solid $grid-line-color;
        grid-auto-flow: row;
        grid-template-rows: 30px;
        user-select: none;

        .kt-appointment-item {
            position: absolute;
            background-color: $event-background-color;
            width: 140px;
            height: 50px;
            font-size: 11px;
            padding: 3px;
            border-left: 8px solid $event-background-color-left;
            border-radius: 2px;
            cursor: pointer;
            color: $event-text-color;

            &.kt-disabled {
                text-decoration: line-through;
            }

            .kt-appointment-start-time {
            }

            .kt-last-name {
                font-weight: 700;
                text-transform: uppercase;
                color: $event-text-color-dark;
            }

            .kt-appointment-start-time {
            }
        }

        .kt-calendar-day-column-header {
            font-size: 14px;
            border-bottom: 1px solid $grid-line-color;
            padding: 3px;
        }

        .kt-full-hour-row {
            display: grid;
            border-bottom: 1px solid $grid-line-color;

            &.kt-day-hours-labels {
                padding-top: 5px;
                text-align: center;
                font-size: 14px;
            }

            .kt-hour-sub-row {
                //font-size: 12px;
                //border: 1px dotted $grid-line-color;
                &:hover {
                    background-color: $row-hover-color;
                }
            }
        }
    }

    .kt-daylist {
        overflow: auto;
        height: calc(100vh - #{$calendar-nav-bar-height} - #{$nav-bar-height});

        .kt-daylist-head {
            color: $secondary-color;
            font-weight: 600;
            border-bottom: 2px solid $grid-line-color;
            background: white;
            position: sticky;
        }

        .kt-daylist-row {
            display: grid;
            grid-auto-flow: column;
            grid-template-columns: repeat(5,minmax(50px, 1fr));
            height: 40px;
            border-bottom: 1px solid $grid-line-color;
            font-size: 14px;
            cursor: pointer;

            &:hover:not(:first-child) {
                background-color: $row-hover-color-light;
            }

            div {
                border-left: 1px solid $grid-line-color;
                display: flex;

                span {
                    margin: auto;
                    text-overflow: ellipsis;

                    &.kt-left-margin {
                        margin-left: 3px;
                    }
                }
            }
        }
    }
}
