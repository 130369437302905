
.kt-number-widget {
    width: 100%;
    height: 150px;    
    
    border-radius: 25px;
    display: grid;
    
    grid-template-columns: 90px auto;
    grid-template-rows: 30px auto;
    grid-template-areas: 
        "title title"
        "icon content";
    
    color: white;

    // align-items: center;
    // justify-content: left;
        
    margin: 10px 0 10px 0;
    padding: 15px;

    cursor: pointer;
    
    .kt-widget-title {
        grid-area: title;
        text-align: center;
    }

    .kt-widget-content {
        grid-area: content;
    }

    &.primary {
        background-color: $primary-color-light;
        .icon {
            background-color: $primary-color;
        }
    }

    &.secondary {
        background-color: $secondary-color-light;
        .icon {
            background-color: $secondary-color;
        }
    }

    .icon {
        grid-area: icon;
        font-size: 35px;
        
        width: 80px;
        height: 80px;
        border-radius: 100%;
        //margin: 10px;
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .kt-sub-title {
        font-size: 12px;
        width: 150px;
        height: 16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow:hidden;
    }

    .absoluteNumber {
        font-size: 28px;
    }

    .percentageBar {
        width: 100%;
        height: 3px;
        background-color: rgba(255,255,255, 0.3);

        div {
            width: 70%;
            height: 100%;
            background-color: white;
        }
    }

}

.kt-card-widget {
    background-color: white;
    min-height: 50px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding: 5px;

    .kt-card-widget-body {
        font-size: 28px;

        i {
            color: $primary-color;
        }
        .kt-card-widget-text {
            margin-left: 10px;
        }
    }
}