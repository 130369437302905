@import "../colors.scss";
@import "../dimensions.scss";

.kt-patients-page {

    // display: grid;
    // grid-template-rows: 68px 200px;

    .kt-page-header {

        display: grid;
        grid-template-columns: 340px auto auto auto;

        font-size: 1em;

        ul {
            list-style: none;
            padding-inline-start: 10px;
            li {
                display: inline-block;
                border: 1px solid $btn-color;
                padding: 5px 10px 5px 10px;
                margin-right: 3px;

                &:hover {
                    background-color: $btn-color;
                    color: white;
                    cursor: pointer;
                }
            }
        }

        .middle-section {
            display: flex;
            align-items: center;
        }

        .patient-searchform {
            span {
                top: 7px;
            }
            input {
                height: 30px;
                width: 350px;
            }
        }

        .kt-btn {
            height: 30px;
        }

    }

}
