.kt-image-draw-dialog {

    .kt-image-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        min-height: 400px;

        display: flex;
        align-items: center;
        justify-content: center;


        .kt-image {
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid grey;
        }
    }

    .kt-button-toolbar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;

        .kt-stroke-widths {
            margin-left: 20px;
            display: flex;
            align-items: center;

            .kt-stroke-width-button {
                width: 25px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                div {
                    width: 1px;
                    height: 35px;
                    border-radius: 5px;
                }
                .kt-stroke-1 { border-left: 1px solid #000000; }
                .kt-stroke-2 { border-left: 2px solid #000000; }
                .kt-stroke-3 { border-left: 5px solid #000000; }
                .kt-stroke-4 { border-left: 7px solid #000000; }
                .kt-stroke-5 { border-left: 10px solid #000000; }
                .kt-stroke-6 { border-left: 20px solid #000000; }

                &:hover, &.kt-selected {
                    div {
                        border-color: $primary-color;
                    }
                }
            }
        }

        .kt-color-button-wrapper {
            display: flex;
            align-items: center;
            .kt-color-button-label {
                i {
                    margin-left: 5px;
                    font-size: 30px;
                    cursor: pointer;
                }
            }

            .kt-color-button {
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                background-color: transparent;
                width: 40px;
                height: 40px;
                border: none;
                cursor: pointer;
            }

            .kt-color-button::-webkit-color-swatch {
                border-radius: 50%;
                border: 2px solid #000000;
            }
            .kt-color-button::-webkit-color-swatch:hover {
                border-color:$primary-color;
            }

            .kt-color-button::-moz-color-swatch {
                border-radius: 50%;
                border: 2px solid #000000;
            }
            .kt-color-button::-moz-color-swatch:hover {
                border-color:$primary-color;
            }
        }

        .kt-button {
            margin-left: 10px;
            border: 2px solid black;
            border-radius: 50%;
            padding: 10px;

            &.kt-selected {
                border-color: $primary-color;
            }

            &.kt-margin-left {
                margin-left: 20px;
            }

            &:hover {
                border-color: $primary-color;
            }
        }
    }

    // mobile
    @media screen and (max-width: 700px) {

        .kt-image-wrapper {
            width: 550px;
            height: 130px;
        }
    }

    // mobile
    @media screen and (max-width: 400px) {

        .kt-image-wrapper {
            width: 270px;
            height: 180px;
        }
    }

}